export function initLanguageSwitcher() {
  document.addEventListener("DOMContentLoaded", function () {
    const languageSwitcherButton = document.getElementById("language-switcher-button");
    const languageOptions = document.getElementById("language-options");

    languageSwitcherButton.addEventListener("click", function (event) {
      const isExpanded = this.getAttribute("aria-expanded") === "true";
      this.setAttribute("aria-expanded", !isExpanded);
      languageOptions.classList.toggle("hidden");
    });

    languageOptions.addEventListener("click", function (event) {
      const listItem = event.target.closest("[data-lang]");
      if (listItem) {
        const selectedLang = listItem.getAttribute("data-lang");
        const currentPath = window.location.pathname;

        // Check if Spanish (default language)
        if (selectedLang === "es") {
          // Remove the locale prefix if it's the default language (e.g., /fr or /en)
          const newPath = currentPath.replace(/^\/(fr|en)/, "");
          window.location.href = `${window.location.origin}${newPath}`;
        } else {
          // Add selected locale prefix for non-default languages
          const newPath = currentPath.replace(/^\/(es|fr|en)/, ""); // remove any existing locale prefix
          window.location.href = `${window.location.origin}/${selectedLang}${newPath}`;
        }
      }
    });

    document.addEventListener("click", function (event) {
      if (!languageOptions.contains(event.target) && event.target !== languageSwitcherButton) {
        languageOptions.classList.add("hidden");
        languageSwitcherButton.setAttribute("aria-expanded", "false");
      }
    });
  });
}