import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import { initLanguageSwitcher } from "./language_switcher";

initLanguageSwitcher();

console.info("Bridgetown is loaded!")

document.addEventListener("DOMContentLoaded", function () {
    const languageSelector = document.getElementById("language-selector");
  
    languageSelector.addEventListener("change", function () {
      const selectedLanguageUrl = this.value;
      window.location.href = selectedLanguageUrl;
    });
  });
